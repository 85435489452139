import React, {Component} from 'react';
import PriceDisplay from '../../Global/PriceDisplay';

import './Styles/_ProductPrice.scss';

interface componentProps {
    product: any;
}
 
interface componentState {}
 
class ProductPrice extends Component<componentProps, componentState> {
    constructor(props: componentProps) {
        super(props);
        this.state = {};
    }

    checkForSameMinAndMaxPrice(minPrice: number, maxNumber: number){
        return minPrice === maxNumber;
    }

    render() {
        const { product } = this.props;

        const ListPrice: any = () => {
            if (product.minPrice == null || product.maxPrice == null) {
                return null;
            }

            if (this.checkForSameMinAndMaxPrice(product.minPrice, product.maxPrice)) {
                return <PriceDisplay value={product.minPrice} />;
            }

            return (
                <>
                    <PriceDisplay value={product.minPrice} /> - <PriceDisplay value={product.maxPrice} />
                </>
            );
        };

        const ListContractPrice: any = () => {
            if (!product.minRangeContractPrice && !product.maxRangeContractPrice) {
                return null;
            }

            if (this.checkForSameMinAndMaxPrice(product.minRangeContractPrice, product.maxRangeContractPrice)) {
                return <PriceDisplay value={product.minRangeContractPrice} />;
            }

            return (
                <>
                    <PriceDisplay value={product.minRangeContractPrice} /> - <PriceDisplay value={product.maxRangeContractPrice} />
                </>
            );
        };
        
        const ListSpecialPrice: any = () => {
            if (this.checkForSameMinAndMaxPrice(product.minSpecialPrice, product.maxSpecialPrice)) {
                return <PriceDisplay value={product.minSpecialPrice} />;
            }

            return (
                <>
                    <PriceDisplay value={product.minSpecialPrice} /> - <PriceDisplay value={product.maxSpecialPrice} />
                </>
            );
        };

        if (product.minSpecialPrice && product.maxSpecialPrice && !product.minRangeContractPrice && !product.maxRangeContractPrice) {
            return (
                <div className="product-details-price">
                    <span className="special-price"><ListSpecialPrice /> <span className="original-price">{<ListPrice />}</span></span>
                </div>
            );
        }
        else if(product.minRangeContractPrice && product.maxRangeContractPrice && ListContractPrice){
            return (
                <div className="product-details-price">
                    {<ListContractPrice />}
                </div>
            )
        }
        else {
            return (
                <div className="product-details-price">
                    {<ListPrice />}
                </div>
            );
        }
    }
}
 
export default ProductPrice;