import PromotionOrderLine from '../Interfaces/PromoMatrix/PromotionOrderLine';
import { ProductObjectContainsOptionId, ProductObjectContainsSizeId, ProductObjectContainsUomId } from './ProductsHelper';
import ContractPricePromotionDisplay from "../Interfaces/PromoMatrix/ContractPricePromotionDisplay";
import StandardPromotionDisplay from "../Interfaces/PromoMatrix/StandardPromotionDisplay";

export const PromotionKeys = {
    LineItemDiscount : "lineItemDiscount",
    AppliedDiscounts : "appliedDiscounts",
    DiscountedPromoPrice: "discountedPromoPrice",
    PriceBeforePromo: "priceBeforePromo",
    UnitPriceBeforePromo: "unitPriceBeforePromo",
    LastAppliedPrice: "lastAppliedPrice",
    HasAContractPrice: "hasAContractPrice"
};

export default class PromotionsHelper {

    static MapOrderItemsForPromotions(orderItems : any[]): PromotionOrderLine[]{
        const overallArray : PromotionOrderLine[] = [];
        orderItems.forEach(x => {
            if(x.hasOwnProperty("productOptions") && Array.isArray(x.productOptions) && x.hasOwnProperty("productId")){
                const mappedProductOptionsOrdered = this.MapProductOptionsForPromotions(x.productOptions, x.productId);
                mappedProductOptionsOrdered.forEach(po => {
                    po && overallArray.push(po);
                })
            }
        });
        return overallArray;
    }

    static AssignRowIdToOrderedLineItems(lineItems: PromotionOrderLine[]): void{
        lineItems.forEach((x, i) => x.rowId = i);
    }

    private static MapProductOptionsForPromotions(productOptions: any[], productId: number){
        return productOptions.map(x => {
            return this.MapProductOptionForPromotions(x, productId);
        }).filter(x => x);
    }

    static MapProductOptionForPromotions(productOption: any, productId: number) : PromotionOrderLine|undefined {
        if(!productOption) {return undefined;}

        const {price, specialPrice, productQuantity, optionId, option1, option2, option3, sizeId, appliedDiscounts, productCategories, 
            unitPriceBeforePromo, uomId, sizeRangeId} = productOption;
        
        if(uomId !== null){ // Don't calculate promos for product options with uom ids as they are UOM's
            return undefined;
        }

        return {
            price: unitPriceBeforePromo && !Number.isNaN(unitPriceBeforePromo) ? unitPriceBeforePromo : price && !Number.isNaN(price) ? price : 0,
            quantity: productQuantity && !Number.isNaN(productQuantity) ? productQuantity : 0,
            specialPrice: specialPrice && !Number.isNaN(specialPrice) ? specialPrice : 0,
            lineDiscount: 0,
            productId: productId,
            priceBreakId: !Number.isNaN(optionId) ? optionId : 0,
            options: `${option1 ? option1 : ""}, ${option2 ? option2 : ""}, ${option3 ? option3 : ""}`,
            stockId: !Number.isNaN(sizeId) ? sizeId : 0, 
            skuRangeId: sizeRangeId && sizeRangeId > 0 ? sizeRangeId : null,
            uomArray: "", // UomArray for B2B?
            categoryIdArray: "",
            productCategories: productCategories,
            appliedDiscounts: Array.isArray(appliedDiscounts) ? appliedDiscounts : [],
            total: 0
        };
    }

    static MapBasketBackToOrderItems(basket : PromotionOrderLine[], orderItems : any[]) {
        if (!Array.isArray(basket)) {
            return;
        }
        else if (!Array.isArray(orderItems)) {
            return;
        }

        orderItems.forEach(orderItem => {
            basket.forEach(currentBasketLineItem => {
                const orderItemProductOptions = orderItem.productOptions;
                if(Array.isArray(orderItemProductOptions)){
                    const correspondingProductOption = orderItemProductOptions.find(x => ProductObjectContainsSizeId(x) ?
                        x.sizeId === currentBasketLineItem.stockId && this.CheckForMatchingPriceBreakAndOptionId(x, currentBasketLineItem) : 
                        ProductObjectContainsUomId(x) ? x.uomId === currentBasketLineItem.stockId && this.CheckForMatchingPriceBreakAndOptionId(x, currentBasketLineItem) : 
                        this.CheckForMatchingPriceBreakAndOptionId(x, currentBasketLineItem));
                    if (correspondingProductOption) {
                        correspondingProductOption[PromotionKeys.LineItemDiscount] = currentBasketLineItem.lineDiscount;
                        correspondingProductOption[PromotionKeys.AppliedDiscounts] = currentBasketLineItem.appliedDiscounts;
                        correspondingProductOption[PromotionKeys.DiscountedPromoPrice] = currentBasketLineItem.total;
                        PromotionsHelper.HandleContractPricePromotions(correspondingProductOption, currentBasketLineItem);
                    }
                }
            });
        });
        
        return orderItems;
    }

    static HandleContractPricePromotions(productOption : any, basketLineItem: PromotionOrderLine){
        productOption[PromotionKeys.HasAContractPrice] = basketLineItem.hasAContractPrice;
        // Only set price before promo if current object price and returned price is the same.
        // Difference in price means a Contract Price promotion was applied.
        if(productOption.price === basketLineItem.price){
            productOption[PromotionKeys.PriceBeforePromo] = basketLineItem.price * basketLineItem.quantity;
            
            if(!productOption.hasOwnProperty(PromotionKeys.LastAppliedPrice) || 
                productOption.hasOwnProperty(PromotionKeys.UnitPriceBeforePromo)){
                    productOption[PromotionKeys.LastAppliedPrice] = productOption.price;
            }
        }
        else{
            // Any previous discounts applied will be reflected in the total hence when a contract price is applied
            // the total cost of the product shouldn't include any discounts
            productOption[PromotionKeys.DiscountedPromoPrice] = basketLineItem.total + basketLineItem.lineDiscount;
            // Reset line item discount to 0 since no discounts allowed for contract pricing
            productOption[PromotionKeys.LineItemDiscount] = 0;
            // Set Unit Price Before Promo only when the price of the option changes for the first time
            if(!productOption.hasOwnProperty(PromotionKeys.UnitPriceBeforePromo)){
                productOption[PromotionKeys.UnitPriceBeforePromo] = productOption.price;
            }
            // Once the price returned from the basket is the same as the original product price
            // Apply any discounts from product option special price
            else if(productOption[PromotionKeys.UnitPriceBeforePromo] === basketLineItem.price && 
                !(productOption[PromotionKeys.LineItemDiscount] > 0)){
                    // While not supporting discounts for product option with a CP, 
                    // don't set any discounts from product option special price
                    if(!basketLineItem.hasAContractPrice){ 
                        productOption[PromotionKeys.LineItemDiscount] = productOption.specialPrice > 0 ?  Math.abs(productOption[PromotionKeys.UnitPriceBeforePromo] - productOption.specialPrice) : 0;
                    }
                delete productOption[PromotionKeys.UnitPriceBeforePromo];
            }

            productOption[PromotionKeys.LastAppliedPrice] = productOption.price;
            productOption.price = basketLineItem.price;
        }
    }

    // Since the product details objects from the order reducer reference the same product details object inside of product details reducer,
    // when resetting/clear the cart, the relevant promotion data should be removed.
    static ResetProductOptions(productOptions:any[]){
        if (!Array.isArray(productOptions)) {
            return;
        }

        productOptions.forEach(currentProductOption => {
            delete currentProductOption[PromotionKeys.LineItemDiscount];
            delete currentProductOption[PromotionKeys.AppliedDiscounts];
            delete currentProductOption[PromotionKeys.DiscountedPromoPrice];
            delete currentProductOption[PromotionKeys.PriceBeforePromo];
            if(currentProductOption.hasOwnProperty(PromotionKeys.UnitPriceBeforePromo)){
                currentProductOption.price = currentProductOption[PromotionKeys.UnitPriceBeforePromo];
            }
            delete currentProductOption[PromotionKeys.UnitPriceBeforePromo];
            delete currentProductOption.productQuantity;
        });

        return productOptions;
    }

    // Since the product details objects are initially loaded from an API call (When clicking on a product tile), the order reducer
    // will still have any relevant promotion data but, the product details from the product details reducer/retrieved from the API call will not.
    // Use this function to assign the promotion data from the product details in the order reducer to the product details in product details reducer through
    // passing by reference.
    static AssignPromotionPropertiesToProductOptionsFromOrderItemOptions(productDetailOptions: any[], orderItemProductOptions: any[]){
        if(Array.isArray(productDetailOptions) && Array.isArray(orderItemProductOptions)){
            orderItemProductOptions.forEach((currentProductOption:any) => {
                const correspondingProductDetailOptionIndex = productDetailOptions.findIndex((pd:any) => ProductObjectContainsOptionId(currentProductOption) &&
                    ProductObjectContainsSizeId(currentProductOption) ? 
                    currentProductOption.sizeId === pd.sizeId && currentProductOption.optionId === pd.optionId &&
                    currentProductOption.uomId === pd.uomId : currentProductOption.optionId === pd.optionId && currentProductOption.uomId === pd.uomId);
    
                if(correspondingProductDetailOptionIndex > -1) {
                    productDetailOptions[correspondingProductDetailOptionIndex] = currentProductOption;
                }
            });
        }
        return productDetailOptions;
    }

    static ShouldShowPromotionDescriptions(promoDescriptions : StandardPromotionDisplay[]|ContractPricePromotionDisplay[]){
        return Array.isArray(promoDescriptions) && promoDescriptions.length > 0
    }

    static ShouldShowContractPriceDescriptions(contractPricePromoDescriptions : ContractPricePromotionDisplay[]){
        return this.ShouldShowPromotionDescriptions(contractPricePromoDescriptions) && 
        !(contractPricePromoDescriptions.length === 1 && contractPricePromoDescriptions[0].quantityRange === "0+")
    }

    private static CheckForMatchingPriceBreakAndOptionId(productOption: any, promotionLineItem: PromotionOrderLine){
        return productOption.hasOwnProperty("optionId") && productOption.optionId === promotionLineItem.priceBreakId;
    }
}